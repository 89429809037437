<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">快递业务</el-breadcrumb-item>
        <el-breadcrumb-item>收费明细标准</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <el-form :model="querys" ref="querys" class="search-form" @submit.prevent>
      <el-row>
        <el-form-item label="" label-width="0" prop="keyword">
          <el-col>
            <el-button type="info" icon="back" @click="back()">
              返回
            </el-button>
          </el-col>
        </el-form-item>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="合作商名称：" label-width="130px">
            <el-col>
              <el-select v-model="master.partnerName" placeholder="请选择" style="max-width: 60%" disabled>
                <el-option v-for="item in dict.partners" :key="item.id" :label="item.partnerName" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="联系人：" label-width="90px">
            <el-col>
              <el-input v-model="master.contactName" style="max-width:120px" disabled>
              </el-input>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="联系方式：" label-width="90px">
            <el-col>
              <el-input v-model="master.contactPhone" style="max-width: 100%" disabled>
              </el-input>
            </el-col>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-tabs type="border-card" v-model="tabName" @tab-change="loadTabData">
      <!-- 快递费收费标准 -->
      <el-tab-pane name="emsTab">
        <template #label>
          <span>
            <i class="el-icon-circle-check" style="color: #67c23a"></i>
            快递费收费标准
          </span>
        </template>

        <el-form :inline="true" @submit.prevent>
          <el-input v-model="querys.keyword" style="max-width:160px; margin-right:30px;" placeholder="关键词搜索" @input="keywordChange"> </el-input>

          <el-select v-model="coverMark" placeholder="请选择本次数据上传方式" size="default" style="width:120px; margin-right:30px;">
            <el-option label="增量更新" value="0" />
            <el-option label="覆盖更新" value="1" />
          </el-select>

          <el-upload ref="upload" class="upload-box" action="EmsScale" :auto-upload="true" :limit="1" :http-request="upload">
            <template #trigger>
              <el-button type="primary">选择要导入的文件</el-button>
            </template>
          </el-upload>

          <div class="upload-tip">
            点击下载模板：
            <a target="_blank" :href="EmsTmplUrl"> 快递费收费标准导入模板.xlsx </a>
          </div>

        </el-form>

        <el-table class="table" ref="multipleTable" :data="data.emsScaleData" style="width: 100%" border size="small" max-height="580">
          <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
          <el-table-column prop="expressName" label="快递名称" width="90"> </el-table-column>
          <el-table-column prop="originProvince" label="始发省份" width="90"> </el-table-column>
          <el-table-column prop="originCity" label="始发城市" width="80"> </el-table-column>
          <el-table-column prop="targetProvince" label="目标省份" width="100"> </el-table-column>
          <el-table-column prop="targetCity" label="目标城市" width="120" :show-overflow-tooltip="true"> </el-table-column>
          <!-- <el-table-column prop="feeScale" label="计费标准"> </el-table-column> -->

          <el-table-column prop="feeScale" label="计费标准" width="400">
            <template #default="scope">
              <span class="item" v-for="(item, key) in JSON.parse(scope.row.feeScale)" :key="key">
                {{ item.remark }} ,
                {{ item.price ? "费用：" + item.price + "；" : "" }}
                {{ item.unit ? "续重单价：" + item.unit + "；" : "" }}
                <br v-if="item.index % 2 == 0" />
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="remark" label="计费备注"> </el-table-column>
          <!-- 四舍五入法、去尾法、进位法 -->
          <el-table-column prop="weightType" label="重量取值类型" width="100">
            <template #default="scope">
              <span v-if="scope.row.weightType==0||scope.row.weightType==null">四舍五入法</span>
              <span v-if="scope.row.weightType==1">去尾法</span>
              <span v-if="scope.row.weightType==2">进位法</span>
              <span v-if="scope.row.weightType==3">顺丰取值法</span>
              <span v-if="scope.row.weightType==4">圆通取值法</span>
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="导入时间"> </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- 出库费收费标准 -->
      <el-tab-pane name="outScale">
        <template #label>
          <span>
            <i class="el-icon-warning-outline"></i>
            出库费收费标准
          </span>
        </template>
        <el-form :inline="true" @submit.prevent>
          <el-select v-model="coverMark" placeholder="请选择本次数据上传方式" size="default" style="width:120px; margin-right:20px;">
            <el-option label="增量更新" value="0" />
            <el-option label="覆盖更新" value="1" />
          </el-select>

          <el-upload ref="upload" class="upload-box" action="OutScale" :auto-upload="true" :limit="1" :http-request="upload">
            <template #trigger>
              <el-button type="primary">选择要导入的文件</el-button>
            </template>
          </el-upload>

          <div class="upload-tip">
            点击下载模板：
            <a target="_blank" :href="OutTmplUrl"> 出库费收费标准导入模板.xlsx </a>
          </div>
        </el-form>

        <el-table class="table" ref="multipleTable" :data="data.outScaleData" style="width: 100%" border size="small" max-height="580">
          <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
          <el-table-column prop="bizType" label="费用项目" width="120"> </el-table-column>
          <el-table-column prop="methodName" label="收费内容" width="140"> </el-table-column>
          <el-table-column prop="unitName" label="收费单位" width="100"> </el-table-column>
          <el-table-column prop="unitNumber" label="单位数量" width="100"> </el-table-column>
          <el-table-column prop="unitPrice" label="单位价格" width="100"> </el-table-column>
          <el-table-column prop="sortCode" label="排序号" sortable width="90"> </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
          <el-table-column prop="createDate" label="导入时间"> </el-table-column>
        </el-table>
      </el-tab-pane>

      <!-- 其他收费标准 -->
      <el-tab-pane name="putScale">
        <template #label>
          <span>
            <i class="el-icon-warning-outline"></i>
            其他收费标准
          </span>
        </template>
        <el-form :inline="true" @submit.prevent>
          <el-select v-model="coverMark" placeholder="请选择本次数据上传方式" size="default" style="width:120px; margin-right:20px;">
            <el-option label="增量更新" value="0" />
            <el-option label="覆盖更新" value="1" />
          </el-select>

          <el-upload ref="upload" class="upload-box" action="OtrScale" :auto-upload="true" :limit="1" :http-request="upload">
            <template #trigger>
              <el-button type="primary">选择要导入的文件</el-button>
            </template>
          </el-upload>

          <div class="upload-tip">
            点击下载模板：
            <a target="_blank" :href="OtrTmplUrl"> 其他收费标准导入模板.xlsx </a>
            （包括入库作业费、存储服务费等）
          </div>
        </el-form>

        <el-table class="table" ref="multipleTable" :data="data.otrScaleData" style="width: 100%" border size="small" max-height="580">
          <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
          <el-table-column prop="bizType" label="费用项目" width="120"> </el-table-column>
          <el-table-column prop="methodName" label="收费内容" width="140"> </el-table-column>
          <el-table-column prop="unitName" label="收费单位" width="100"> </el-table-column>
          <el-table-column prop="unitNumber" label="单位数量" width="100"> </el-table-column>
          <el-table-column prop="unitPrice" label="单位价格" width="100"> </el-table-column>
          <el-table-column prop="sortCode" label="排序号" sortable width="90"> </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
          <el-table-column prop="createDate" label="导入时间"> </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>
<script>
import { uploader } from "@/api/subupload";
import {
  importEmsScale, fetchPartnerList, fetchPartner, fetchEmsScaleList,
  fetchOutScaleList, importOutScale, fetchOtrScaleList, importOtrScale
} from "@/api/mailscale";
export default {
  name: "PartnerDetail",
  data() {
    return {
      EmsTmplUrl: process.env.VUE_APP_BASEURL + "/static/template/MailEmsTmplScale.xlsx",
      OutTmplUrl: process.env.VUE_APP_BASEURL + "/static/template/MailOutTmplScale.xlsx",
      OtrTmplUrl: process.env.VUE_APP_BASEURL + "/static/template/MailOtrTmplScale.xlsx",
      tabName: "emsTab",
      master: {},
      querys: {
        keyword: "",
      },
      coverMark: '0',
      data: {},
      dict: {
        partners: []
      },
    };
  },
  methods: {
    fetchs() {
    },
    back() {
      this.$router.go(-1);
    },
    searchEvent() {

    },

    submitEvent() {

    },
    exportEvent() {
    },
    upload(file) {
      var dataList = new FormData();
      dataList.append(file.file.name, file.file);
      dataList.append("fileType", "excel");
      dataList.append("dataType", file.action);
      this.$refs.upload.clearFiles();

      uploader(dataList)
        .then((res) => {
          if (res.code === 200) {
            this.$message({ message: "上传成功", type: "success" });
            this.dataId = res.data[0].id;
          } else {
            this.$message.error("上传失败，请重试");
          }
        })
        .then(() => {
          this.loading = false;
          if (file.action == 'EmsScale') {
            importEmsScale(this.dataId, this.id, this.coverMark).then(
              (res) => {
                if (res.code === 200) {
                  this.loadTabData();
                }
              },
              (err) => {
                console.log(err);
              }
            );
          } else if (file.action == 'OutScale') {
            importOutScale(this.dataId, this.id, this.coverMark).then(
              (res) => {
                if (res.code === 200) {
                  this.loadTabData();
                }
              },
              (err) => {
                console.log(err);
              }
            );
          } else if (file.action == 'OtrScale') {
            importOtrScale(this.dataId, this.id, this.coverMark).then(
              (res) => {
                if (res.code === 200) {
                  this.loadTabData();
                }
              },
              (err) => {
                console.log(err);
              }
            );
          }
        });
    },
    loadTabData(changeTabName) {
      changeTabName = (changeTabName || this.tabName);
      if (changeTabName == 'emsTab') {
        fetchEmsScaleList(this.id, this.querys.keyword).then((res) => {
          this.data.emsScaleData = res.data.data;
        });
      } else if (changeTabName == 'outScale') {
        fetchOutScaleList(this.id).then((res) => {
          this.data.outScaleData = res.data.data;
        });
      } else if (changeTabName == 'putScale') {
        fetchOtrScaleList(this.id).then((res) => {
          this.data.otrScaleData = res.data.data;
        });
      }
    },
    keywordChange(v) {
      setTimeout(() => {
        if (v == this.querys.keyword) {
          console.log(this.querys.keyword, 'request');
          this.loadTabData();
        }
      }, 400);
    }
  },
  //在Vue的生命周期Created函数中注册相关事件
  created() {
  },
  mounted() {
    var that = this;
    that.id = that.$route.query.id;

    fetchPartnerList({ columns: [{ prop: 'id', prop: 'partnerName' }] }).then((res) => {
      that.dict.partners = res.data.data;

      if (that.id) {
        fetchPartner(that.id).then((res) => {
          this.master = { ...res.data };
        });
      }
    });

    this.loadTabData();
  },
  computed: {
  },
};
</script>

<style scoped>
.upload-box {
  display: inline-block;
}
.upload-tip {
  font-size: 12px;
  margin-left: 12px;
  display: inline-block;
}
</style>